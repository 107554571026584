export const GENERATE_WORK = "GENERATE_WORK";
export const PROCESS_WORK = "PROCESS_WORK";
export const REMOVE_WORK = "REMOVE_WORK";
export const PROCESS_WORK_AS_ONE_REQUEST = "PROCESS_WORK_AS_ONE_REQUEST";
export const SET_WEBPRAISAL_VALUE = "SET_WEBPRAISAL_VALUE";
export const APPEND_WEBPRAISAL_VALUE = "APPEND_WEBPRAISAL_VALUE";
export const SET_WEBPRAISAL_STATUS = "SET_WEBPRAISAL_STATUS";
export const SPLICE_WEBPRAISAL_INDEX = "SPLICE_WEBPRAISAL_INDEX";
export const SET_IS_LOADING_TRUE = "SET_IS_LOADING_TRUE";
export const WEBPRAISAL_SET_RESULT = "WEBPRAISAL_SET_RESULT";
export const SURVEY_RESPONSE = "SURVEY_RESPONSE";

export const LANDER_CHANGE_PANEL = "LANDER_CHANGE_PANEL";

export const landerChangePanel = (data) => ({
    type: LANDER_CHANGE_PANEL,
    payload: data,
});

export const surveyResponse = (data) => ({
    type: SURVEY_RESPONSE,
    payload: data,
});

export const removeWork = (data) => ({
    type: REMOVE_WORK,
    payload: data,
});

export const webpraisalSetResult = (data) => ({
    type: WEBPRAISAL_SET_RESULT,
    payload: data,
});

export const PROCESS_WORK_AS_ASSISTANT = "PROCESS_WORK_AS_ASSISTANT";

export const processWorkAsAssistant = () => ({
    type: PROCESS_WORK_AS_ASSISTANT,
});

export const setIsLoadingTrue = () => ({
    type: SET_IS_LOADING_TRUE
});

export const appendWebpraisalValue = (data) => ({
    type: APPEND_WEBPRAISAL_VALUE,
    payload: data,
});

export const setWebpraisalValue = (data) => ({
    type: SET_WEBPRAISAL_VALUE,
    payload: data,
});

export const setWebpraisalStatus = (data) => ({
    type: SET_WEBPRAISAL_STATUS,
    payload: data,
});

export const spliceWebpraisalIndex = (data) => ({
    type: SPLICE_WEBPRAISAL_INDEX,
    payload: data,
});

export const generateWork = (data) => ({
    type: GENERATE_WORK,
    payload: data,
});

export const processWork = (data) => ({
    type: PROCESS_WORK,
    payload: data,
});

export const processWorkAsOneRequest = () => ({
    type: PROCESS_WORK_AS_ONE_REQUEST
});