import React from "react";
import { connect } from "react-redux";
import AdminLayout from "../../components/admin/Layout";
import SingleUploadPanel from "../../components/lander/SingleUploadPanel";
import {
    firebaseGetSettings,
    firebaseUpdateSettings,
} from "../../store/Actions";

const AdminSettings = ({
    doFirebaseGetSettings,
    doFirebaseUpdateSettings,
    openAiTurbo,
    user,
}) => {

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">
                Settings
            </div>
            <div className="flex flex-col max-w-5xl p-5">
                <SingleUploadPanel isAdmin={true}/>
            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetSettings: () => dispatch(firebaseGetSettings()),
    doFirebaseUpdateSettings: (data) => dispatch(firebaseUpdateSettings(data)),
});

const mapStateToProps = (state) => ({
    openAiTurbo: state.firebase.settings.openAiTurbo,
    user: state.userSlice,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);