import React from "react";
import { connect } from "react-redux";
import ClientLayout from "../../components/client/Layout";
import CollectionItem from "../../components/client/CollectionItem";
import {

} from "../../store/Actions";

const ClientCollection = ({
    requests,
}) => {
    let testData = Array(10).fill(0);
    return (
        <ClientLayout>

        <div>
            <div className="text-3xl w-full ml-6 pr-5 flex flex-row justify-between">
                Collections > Collection (Lot)
                <div className="bg-gray-100/50 border-2 hover:bg-green-200/50">
                    + Add Item To Collection
                </div>
            </div>

            <div className="ml-4 bg-off-white rounded-2xl overflow-hidden">
                <div className="flex flex-row justify-between bg-blue-100 rounded-br-2xl p-2 pt-3">
                    <div className="text-2xl font-bold pl-2">
                        Nes Video Games - 11 Items
                    </div>
                    <div className="flex flex-row text-xl">
                        <div className="m-1 p-1 px-2 bg-green-100 hover:bg-green-200">
                            Edit Lot
                        </div>
                        <div className="m-1 p-1 px-2 bg-green-100 hover:bg-green-200">
                            Collection Actions
                        </div>
                    </div>

                </div>

                <div className="bg-blue-100">
                    <div className="grid grid-cols-6 p-2 gap-6 bg-off-white rounded-tl-2xl">
                        { testData.map((request, index) => (
                            <CollectionItem index={index} />
                        ))}
                        <div className="shadow-lg rounded-xl flex flex-col text-center hover:cursor-pointer hover:opacity-80 overflow-hidden relative">
                            <div className="h-1/2 bg-red-300 rounded-br-2xl z-10 overflow-hidden">
                                <img className="h-fit p-3" src="/test-images/bicycle.jpg" alt="mario"/>
                            </div>
                            <div className="bg-white h-1/2 w-full absolute top-0"></div>
                            <div className="bg-red-300 h-1/2 w-full absolute bottom-0"></div>
                            <div className="h-1/2 bg-white rounded-tl-2xl z-10">
                                <div className="text-xl">Super Mario Bros</div>
                                <div className="text-xl">19.99</div>
                            </div>
                        </div>

                        <div className="shadow-lg rounded-xl flex flex-col text-center hover:cursor-pointer overflow-hidden relative">
                            <div className="h-1/2 bg-red-300 rounded-br-2xl z-10">
                            </div>
                            <div className="bg-white h-1/2 w-full absolute top-0"></div>
                            <div className="bg-red-300 h-1/2 w-full absolute bottom-0"></div>
                            <div className="h-1/2 bg-white rounded-tl-2xl z-10">
                                <div className="text-3xl">Add Item</div>
                                <div className="text-3xl">+</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="grid grid-cols-7 m-5 bg-slate-300 rounded-xl p-2 text-center">
                <div>#</div>
                <div>photo</div>
                <div>title</div>
                <div>price</div>
                <div>created</div>
                <div>status</div>
                <div>actions</div>
            </div>

            { testData.map((request) => (

                <div className="grid grid-cols-7 m-5 bg-blue-100 rounded-xl text-center shadow-md overflow-hidden">
                    <div className="justify-center flex flex-col bg-blue-100">1</div>
                    <div className="bg-blue-100 p-3">
                        <div className="m-auto rounded-xl flex flex-col text-center hover:cursor-pointer overflow-hidden relative h-fit w-24">
                            <div className="h-4/5 bg-white z-10 overflow-hidden">
                                <img className="h-fit p-3" src="/test-images/marioCatridge.jpg" alt="mario"/>
                            </div>
                        </div>
                    </div>
                    <div className="bg-blue-100 justify-center flex flex-col">Super Mario Bros...</div>
                    <div className="bg-blue-100 justify-center flex flex-col">19.99</div>
                    <div className="bg-blue-100 justify-center flex flex-col">12-24-2023</div>
                    <div className="bg-blue-100 justify-center flex flex-col">Not Posted</div>
                    <div className="bg-blue-100 justify-center flex flex-col">
                        <div className="flex flex-row">
                            <button className="m-2 p-2 bg-green-200 hover:bg-green-300 rounded-md">edit</button>
                            <button className="m-2 p-2 bg-blue-200 hover:bg-blue-300 rounded-md">view</button>
                        </div>
                    </div>
                    <div className="col-span-7 bg-smoke-white h-1 rounded-tl-2xl relative z-10 rounded-tl-2xl">
                        <div className="bg-smoke-white h-3 w-full w-1 z-10 rounded-tl-xl"></div>
                        <div className="absolute bg-slate-200 h-3 right-0 w-3 bottom-1 rounded-br-xl"></div>
                        <div className="absolute bg-smoke-white h-1 right-0 w-3 bottom-1 -z-10"></div>
                    </div>
                </div>
            ))}




            <div className="p-5">

                <table class="table-auto text-center w-full overflow-hidden rounded-xl">
                    <thead className="bg-slate-300 text-lg rounded-t-xl">
                        <tr className="">
                            <th className="">#</th>
                            <th className="">item</th>
                            <th className="text-center">title</th>
                            <th className="text-center">price</th>
                            <th className="text-center">created</th>
                            <th className="">status</th>
                            <th>actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-xl">
                    { testData.map((request) => (
                        <tr className="hover:bg-slate-300 shadow-sm overflow-hidden rounded-xl">
                            <td className="bg-blue-200 rounded-2xl">1</td>
                            <td className="">
                                <div className="m-auto rounded-xl flex flex-col text-center overflow-hidden relative h-fit w-24">
                                    <div className="h-4/5 bg-smoke-white z-10 overflow-hidden">
                                        <img className="h-fit p-3" src="/test-images/marioCatridge.jpg" alt="mario"/>
                                    </div>
                                </div>
                            </td>
                            <td className="">Super Mario Bros...</td>
                            <td className="">19.99</td>
                            <td className=""></td>
                            <td className="">...</td>
                            <td className="float-right">
                                <div className="flex flex-row">
                                <button className="m-2 p-2 bg-green-200 hover:bg-green-300 rounded-md">edit</button>
                                <button className="m-2 p-2 bg-blue-200 hover:bg-blue-300 rounded-md">view</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </div>

        </ClientLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    user: state.userSlice,
    token: state.userSlice.token,
    requests: state.firebase.requests.edges,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCollection);