import React, { useState } from "react";

import { connect } from "react-redux";

import Header from "../components/layout/Header";
import BouncyButton from "../components/lander/BouncyButton";

import {
    firebaseContactUs,
} from "../store/Actions";
import ReCAPTCHA from "react-google-recaptcha";

function Lander({
    landerPanel,
    doFirebaseContactUs,
}) {
    const recaptchaRef = React.createRef();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (recaptchaValue) {
            if (email === "" || message === "") return;
            setFormSubmitted(true);
            doFirebaseContactUs({
                email: email,
                message: message,
            })
        }
    }
    return (
        <div className="px-4 pt-4 bg-off-white pb-10">
            <Header/>


            <div className="max-w-3xl m-auto border-1 border-dark-blue rounded-lg overflow-hidden mt-20 bg-contact-us h-48 md:h-96 bg-repeat-x bg-contain">
            </div>
            <div className="text-4xl text-teal-text text-center font-bold mb-10 mt-5">
                Contact Us
            </div>
            <div className="text-center flex flex-col max-w-xl m-auto">
                { formSubmitted &&
                    <div className="text-3xl text-teal-text">
                        Thank you for your message
                    </div>
                }
                { !formSubmitted &&

                    <form onSubmit={handleSubmit} className="flex flex-col text-center">
                        <input className="w-full m-auto p-2 text-xl text-left bg-smoke-white border-1 border-gray-300 rounded-xl hover:border-blue-300 focus:outline-none focus:border-blue-400 text-teal-text"
                            type="text"
                            placeholder="Email"
                            onChange={handleEmailChange}
                        />
                        <textarea className="w-full m-auto p-2 my-5 text-xl text-left bg-smoke-white border-1 border-gray-300 rounded-xl hover:border-blue-300 focus:outline-none focus:border-blue-400 text-teal-text"
                            placeholder="Message"
                            rows="5"
                            onChange={handleMessageChange}/>
                         <div className="pt-1 pb-5 m-auto w-fit">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdYqjQpAAAAAFNpjK2xVMybi7Z7PaWe5K8379J4"
                                onChange={handleRecaptchaChange}
                            />
                        </div>
                        <BouncyButton>
                            Contact
                        </BouncyButton>
                    </form>
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseContactUs: (data) => dispatch(firebaseContactUs(data)),
});

const mapStateToProps = (state) => ({
    landerPanel: state.webpraisal.landerPanel,
});

export default connect(mapStateToProps, mapDispatchToProps)(Lander);
