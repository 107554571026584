import React from "react";
import { connect } from "react-redux";
import BouncyButton from "../lander/BouncyButton";

const RecursiveComponent = ({data}) => {
    const isObject = (val) => typeof val === 'object' && val !== null;

    const renderContent = (obj) => {
        return Object.entries(obj).map(([key, value], index) => {
            if (key === "images") return false;
            if (isObject(value)) {
                return (
                    <div key={index} className="grid grid-cols-1 md:grid-cols-4 pt-2">
                        <div><strong>{key}:</strong></div>
                        <div className="col-span-3">
                            <RecursiveComponent data={value} />
                        </div>
                    </div>
                );
            }

            return (
                <div key={index} className="grid grid-cols-1 md:grid-cols-4 pt-2">
                    <div><strong>{key}:</strong></div>
                    <div className="md:col-span-3"> {value.toString()}</div>
                </div>
            );
        });
    };

    return <div>{renderContent(data)}</div>;
}

function objectToString(obj, indentLevel = 0) {
    if (typeof obj !== "object" || obj === null) {
        return String(obj);
    }

    const indent = " ".repeat(indentLevel * 4); // Indentation for readability
    let str = "\n";
    Object.entries(obj).forEach(([key, value]) => {
        if (key === "images") return false;
        if (key === "Title") return false;
        str += `${indent}    ${key}: ${
            typeof value === "object" ? '\n' + objectToString(value, indentLevel + 1) : value
        }\n`;
    });
    str += `${indent}`;
    return str;
}

function DisplayResults({
    results,
    resultsBoxFade,
    eBayPriceCheckResults,
    eBayPriceCheckIsLoading,
}) {

    const copyToClipboard = async () => {
        let text = objectToString(results);
        if ("clipboard" in navigator) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            textarea.remove();
        }
    };

    return (
        <div className={`rounded-xl w-full bg-smoke-white py-4 px-6 results-panel ${resultsBoxFade && "fade-in"}`}>
            <div className="grid grid-cols-3 gap-4">
                {results.images?.map((file, index) => {
                    if (file.type !== "image") return false;
                    return(
                    <div key={`${file.path}_${index}`}
                        className={`border-2 p-1 w-full h-full rounded-lg overflow-hidden relative justify-between flex flex-col
                            ${ file.status === "waiting" && "bg-stone-200"}
                            ${ file.status === "complete" && "bg-green-500"}
                            ${ file.status === "loading" && "bg-blue-200"}
                             ${ file.status === "error" && "bg-red-500"}`}>
                        <img src={file.preview} alt={file.path} className="max-h-24 m-auto"/>
                    </div>
                    )
                })}
            </div>
            <div className="pt-4 text-lg font-bold">
                { results?.Title }
            </div>
            <div className="grid grid-cols-3">
                <div className="col-span-3 text-center">
                    Results From Ebay:
                </div>
                { eBayPriceCheckIsLoading &&
                    <div className="col-span-3 text-center">
                        Loading Similar Items on Ebay...
                    </div>
                }
                { !eBayPriceCheckIsLoading &&
                    eBayPriceCheckResults.map((item) => {
                        return(
                            <div key={item["id"]} className="border-1 text-center">
                                <div>{item["title"].substring(0, 40)}...</div>
                                <div>
                                    {item["price"]}
                                </div>
                                <img src={item["image"]} alt={item["title"]}/>
                            </div>
                        )
                    })
                }
            </div>
            <RecursiveComponent data={ results }/>
            <div className="text-center pt-5 pb-5">
                <BouncyButton handleSubmit={copyToClipboard}>
                    Copy To Clipboard
                </BouncyButton>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    resultsBoxFade: state.webpraisal.resultsBoxFade,
    eBayPriceCheckIsLoading: state.webpraisal.eBayPriceCheckIsLoading,
    eBayPriceCheckResults: state.webpraisal.eBayPriceCheckResults,
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayResults);
