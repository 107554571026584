import React from "react";
import { connect } from "react-redux";
import ClientLayout from "../../components/client/Layout";
import CollectionsItem from "../../components/client/CollectionsItem";
import {

} from "../../store/Actions";

const ClientListCollections = ({
    requests,
}) => {

    let testData = Array(10).fill(0);
    return (
        <ClientLayout>
            <div className="text-3xl ml-4 flex flex-row justify-between">
                <div>
                    Collections (Lots)
                </div>
                <div className="bg-gray-100/50 border-2 hover:bg-green-200/50">
                    + Add Collection
                </div>
            </div>

            <div className="mx-4 mt-5 grid grid-cols-2 gap-5">
                { testData.map((request, index) => (
                    <CollectionsItem index={index}/>
                ))}
            </div>
            <div className="flex flex-row">
                <div className="bg-pink-300 h-10 w-10 z-10"></div>
                <div className="bg-orange-300 h-10 w-10 z-20"></div>
                <div className="bg-green-300 h-10 w-10 z-30"></div>
                <div className="bg-blue-300 h-10 w-10 z-40"></div>
                <div className="bg-yellow-300 h-10 w-10 z-50"></div>
                <div className="bg-amber-300 h-10 w-10"></div>
                <div className="bg-lime-300 h-10 w-10"></div>
                <div className="bg-emerald-300 h-10 w-10"></div>
                <div className="bg-teal-300 h-10 w-10"></div>
                <div className="bg-cyan-300 h-10 w-10"></div>
                <div className="bg-sky-300 h-10 w-10"></div>
                <div className="bg-violet-300 h-10 w-10"></div>
                <div className="bg-purple-300 h-10 w-10"></div>
                <div className="bg-fuchsia-300 h-10 w-10"></div>
                <div className="bg-pink-300 h-10 w-10"></div>
                <div className="bg-rose-300 h-10 w-10"></div>
            </div>
            <div className="flex flex-row">
                <div className="bg-pink-400 h-10 w-10"></div>
                <div className="bg-orange-400 h-10 w-10"></div>
                <div className="bg-green-400 h-10 w-10"></div>
                <div className="bg-blue-400 h-10 w-10"></div>
                <div className="bg-yellow-400 h-10 w-10"></div>
                <div className="bg-amber-400 h-10 w-10"></div>
                <div className="bg-lime-400 h-10 w-10"></div>
                <div className="bg-emerald-400 h-10 w-10"></div>
                <div className="bg-teal-400 h-10 w-10"></div>
                <div className="bg-cyan-400 h-10 w-10"></div>
                <div className="bg-sky-400 h-10 w-10"></div>
                <div className="bg-violet-400 h-10 w-10"></div>
                <div className="bg-purple-400 h-10 w-10"></div>
                <div className="bg-fuchsia-400 h-10 w-10"></div>
                <div className="bg-pink-400 h-10 w-10"></div>
                <div className="bg-rose-400 h-10 w-10"></div>
            </div>
        </ClientLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    user: state.userSlice,
    token: state.userSlice.token,
    requests: state.firebase.requests.edges,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientListCollections);