import React, { useState } from "react";

import { connect } from "react-redux";

function CollectionItem({
    index,
    children,
    handleSubmit,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
        <div className="flex flex-col bg-gray-200/40 rounded-xl"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleClick}
                onMouseUp={handleUnclick}>
            <div key={index} className={`shadow-md rounded-xl flex flex-col text-center hover:cursor-pointer  overflow-hidden relative bouncy-button ${activeClass} relative`}>
                <div className="h-1/2 bg-red-200 rounded-br-2xl z-10 overflow-hidden">
                    <img className="h-fit p-3" src="/test-images/marioCatridge.jpg" alt="mario"/>
                </div>
                <div className="bg-white h-1/2 w-full absolute top-0"></div>
                <div className="bg-red-200 h-1/2 w-full absolute bottom-0"></div>
                <div className="h-1/2 bg-white rounded-tl-2xl z-10">
                    <div className="text-xl">Super Mario Bros</div>
                    <div className="text-xl">19.99</div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionItem);
