import { all, fork, takeLeading, put, call, select, delay } from "redux-saga/effects";

import {
    PROCESS_WORK,
    PROCESS_WORK_AS_ONE_REQUEST,
    PROCESS_WORK_AS_ASSISTANT,
    SURVEY_RESPONSE,
    LANDER_CHANGE_PANEL,
    setWebpraisalValue,
    appendWebpraisalValue,
    setWebpraisalStatus,
    // webpraisalSetResult,
} from "../../store/Actions";

import {
    fetchOpenAiText,
    fetchOpenAiVision,
    fetchOpenAiVisionOneIdentify,
    fetchOpenAiPriceCheck,
    fetchAssistantPriceCheckThread,
    fetchAssistantPriceCheckQueue,
    fetchSetSurveyResponse,
    fetchGetEbayPriceCheck,
    // fetchPerplexityPriceCheck,
} from "../../api/Api";

const workToBeDone = (state) => state.webpraisal.workToBeDone;
const requestId = (state) => state.webpraisal.requestId;

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const loadingMessages = [
    "Spooling up the necessary services...",
    "Contacting the relevant gnomes for price checks...",
    "Encountering empty carts...",
    "Connecting price checks...",
];

function* processWorkAsAssistant() {
    try {
        ///// => seperate action to beginProcessWork
        yield put(setWebpraisalValue({
            index: "requestId",
            value: null,
        }))
        yield put(setWebpraisalValue({
            index:"results",
            value:[]
        }));
        yield put(setWebpraisalValue({
            index:"isLoading",
            value:true
        }));
        yield put(setWebpraisalValue({
            index:"isError",
            value:false
        }));
        yield put(setWebpraisalValue({
            index:"status",
            value:"Indentifying Item"
        }));
        yield put(setWebpraisalValue({
            index: "showSurvey",
            value: false,
        }));
        yield put(setWebpraisalValue({
            index: "loadBar",
            value: 0,
        }));
        yield put(setWebpraisalValue({
            index: "loadingBoxFade",
            value: false,
        }));
        yield put(setWebpraisalValue({
            index: "loadingBoxFade",
            value: false,
        }));
        yield put(setWebpraisalValue({
            index: "resultsBoxFade",
            value: false,
        }));
        yield put(setWebpraisalValue({
            index: "eBayPriceCheckIsLoading",
            value: true,
        }));
        yield put(setWebpraisalValue({
            index: "eBayPriceCheckResults",
            value: [],
        }));

        const payload = yield select(workToBeDone);
        const response = yield call(fetchOpenAiVisionOneIdentify, payload);
        yield put(setWebpraisalValue({
            index: "requestId",
            value: response.data.requestId,
        }))
        yield put(setWebpraisalValue({
            index:"status",
            value:"Creating Price Request Thread"
        }));
        yield put(setWebpraisalValue({
            index: "loadBar",
            value: 1,
        }));

        const threadResponse = yield call(fetchAssistantPriceCheckThread,  response.data);

        yield put(setWebpraisalValue({
            index: "loadBar",
            value: 2,
        }));
        yield put(setWebpraisalValue({
            index:"status",
            value:"Waiting For Lookup To Complete"
        }));

        let queueResponse = yield call(fetchAssistantPriceCheckQueue, {
                data:threadResponse.data.data,
                requestId: response.data.requestId,
            });
        yield put(setWebpraisalValue({
            index: "loadBar",
            value: 3,
        }));
        while (queueResponse.data.data === "in_progress") {
            queueResponse = yield call(fetchAssistantPriceCheckQueue, {
                    data:threadResponse.data.data,
                    requestId: response.data.requestId,
                });
            yield put(setWebpraisalValue({index:"status", value:loadingMessages[Math.floor(Math.random() * loadingMessages.length)]}));
            yield delay(1000);
        }
        yield put(setWebpraisalValue({
            index: "loadBar",
            value: 4,
        }));
        yield put(setWebpraisalValue({
            index: "loadingBoxFade",
            value: true,
        }));
        yield delay(500);

        let queueJson;
        if (isJsonString(queueResponse.data.data)) {
            queueJson = JSON.parse(queueResponse.data.data);
            yield put(setWebpraisalValue({
                index: "results",
                value: {
                    ...queueJson,
                    images: payload,
                }
            }));
        } else {
            //Check if two "" and place a comma between and try again
            let secondChanceJson = queueResponse.data.data.replace(/""/g,'","');
            if (isJsonString(secondChanceJson)) {
                let secondQueueJson = JSON.parse(secondChanceJson);
                yield put(setWebpraisalValue({
                    index: "results",
                    value: {
                        ...secondQueueJson,
                        images: payload,
                    }
                }));
            } else {
                yield put(setWebpraisalValue({
                    index: "results",
                    value: {
                        data: queueResponse.data.data,
                        images: payload,
                    }
                }));
            }
        }
        ////////// => seperate action endProcessWork
        yield put(setWebpraisalValue({
            index: "status",
            value: "Returning Price"
        }));
        yield put(setWebpraisalValue({
            index: "isLoading",
            value: false
        }));
        yield put(setWebpraisalValue({
            index: "workToBeDone",
            value: []
        }));
        yield put(setWebpraisalValue({
            index: "resultsBoxFade",
            value: true,
        }));

        if (queueJson && queueJson["Title"]) {
            const eBayPriceCheck = yield call(fetchGetEbayPriceCheck, {
                "title": queueJson["Title"],
            });
            yield put(setWebpraisalValue({
                index: "eBayPriceCheckIsLoading",
                value: false,
            }));
            yield put(setWebpraisalValue({
                index: "eBayPriceCheckResults",
                value: eBayPriceCheck.data,
            }));
        }


        yield delay(1500);
        yield put(setWebpraisalValue({
            index: "showSurvey",
            value: true,
        }));
    } catch (error) {
        yield put(setWebpraisalValue({index:"isError", value:true}));
        yield put(setWebpraisalValue({index:"isLoading", value:false}));
    }

}

function* processWorkAsOneRequest() {
    try {
        yield put(setWebpraisalValue({index:"results", value:[]}));
        yield put(setWebpraisalValue({index:"isLoading", value:true}));
        yield put(setWebpraisalValue({index:"isError", value:false}));
        const payload = yield select(workToBeDone);
        console.log(payload);


        const response = yield call(fetchOpenAiVisionOneIdentify, payload);
        // console.log(response);
        yield put(appendWebpraisalValue({
                    index: "results",
                    value: [
                        "Item Identifed as:",
                        response.data.data,
                    ]
                }));

        // const ppResponse = yield call(fetchPerplexityPriceCheck, response.data);
        // yield put(appendWebpraisalValue({
        //             index: "results",
        //             value: [
        //                 "Perplexity Price At:",
        //                 ppResponse.data.data,
        //             ]
        //         }));

        const priceResponse = yield call(fetchOpenAiPriceCheck, response.data);
        yield put(appendWebpraisalValue({
                    index: "results",
                    value: [
                        "Item Price At:",
                        priceResponse.data.data,
                    ]
                }));

        yield put(setWebpraisalValue({index:"isLoading", value:false}));
        yield put(setWebpraisalValue({index:"workToBeDone", value:[]}));
    } catch (error) {
        yield put(setWebpraisalValue({index:"isError", value:true}));
    }
}

function* processWork() {
    try {
        yield put(setWebpraisalValue({index:"isLoading", value:true}));
        yield put(setWebpraisalValue({index:"isError", value:false}));
        const payload = yield select(workToBeDone);
        for (let item of payload) {
            if (item.status !== "waiting" && item.status !== "error") continue;
            yield put(setWebpraisalStatus({
                index: item.index,
                status: "loading",
            }));

            try {
                let response;
                if (item.type === "text") {
                    response = yield call(fetchOpenAiText, item.data);
                } else if (item.type === "image") {
                    response = yield call(fetchOpenAiVision, item.data);
                }
                yield put(appendWebpraisalValue({
                    index: "results",
                    value: [
                        `${item.path}_${item.index}`,
                        response.data.data,
                    ]
                }));
                yield put(setWebpraisalStatus({
                    index: item.index,
                    status: "complete",
                }));
            } catch (error) {
                yield put(setWebpraisalStatus({
                    index: item.index,
                    status: "error",
                }));
            }
        }
        yield put(setWebpraisalValue({index:"isLoading", value:false}));
    } catch (error) {
        yield put(setWebpraisalValue({index:"isError", value:true}));
    }

};

function* sendSurveyResponse(data) {
    const currentRequestId = yield select(requestId);
    console.log(data.payload);
    yield call(fetchSetSurveyResponse, {
        requestId: currentRequestId,
        surveyResponse: data.payload,
    });
    yield delay(5000);
    yield put(setWebpraisalValue({
        index: "showSurvey",
        value: false,
    }));
}

function* landerChangePanel(data) {
    yield put(setWebpraisalValue({
        index: "landerPanel",
        value: data.payload,
    }));
}

function* watchDataSaga() {
    yield takeLeading(PROCESS_WORK, processWork);
    yield takeLeading(PROCESS_WORK_AS_ONE_REQUEST, processWorkAsOneRequest);
    yield takeLeading(PROCESS_WORK_AS_ASSISTANT, processWorkAsAssistant);
    yield takeLeading(SURVEY_RESPONSE, sendSurveyResponse);
    yield takeLeading(LANDER_CHANGE_PANEL, landerChangePanel);
}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}