import React, { useState } from "react";

import { connect } from "react-redux";

function CollectionItem({
    index,
    children,
    handleSubmit,
    bgColor,
}) {
    const [activeClass, setActiveClass] = useState("");

    const [randomButtonBgColor] = useState(`${bgColor}-400`);

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }

    return (
        <div
            index={index}
            className={`relative hover:cursor-pointer`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}>
            <div className="absolute rounded-2xl bg-gray-200 h-full w-full top-0  hover:cursor-pointer -z-30"></div>
            <div className={`${randomButtonBgColor} rounded-2xl shadow-md p-2 bouncy-button ${activeClass} hover:cursor-pointer z-40`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleClick}
                onMouseUp={handleUnclick}>
                Edit Lot
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionItem);
