import React, { useState } from "react";
import { connect } from "react-redux";
import BouncyButton from "../lander/BouncyButton";
import {
    surveyResponse
} from "../../store/Actions";

function Survey({
    doSurveyResponse,
}) {
    const [surveySubmitted, setSurveySubmitted] = useState(false);
    const handleResponse = response => () => {
        setSurveySubmitted(true);
        doSurveyResponse(response);
    }
    return (
        <div className="max-w-xl m-auto flex flex-col bg-smoke-white rounded-xl mt-10 px-2 border-1 border-dark-blue py-5">
            { !surveySubmitted &&
                <>
                <div className="text-center text-xl">
                    Was the response what you expected and accurate?
                </div>
                <div className="grid grid-cols-2 pt-4 md:px-24 gap-5">
                    <div className="text-center md:pt-5 pb-5">
                        <BouncyButton handleSubmit={handleResponse("Yes")}>
                            &nbsp;&nbsp;Yes&nbsp;&nbsp;
                        </BouncyButton>
                    </div>
                    <div className="text-center md:pt-5 pb-5">
                        <BouncyButton handleSubmit={handleResponse("No")}>
                            &nbsp;&nbsp;No&nbsp;&nbsp;
                        </BouncyButton>
                    </div>
                </div>
                </>
            }
            { surveySubmitted &&
                <div className="text-center text-xl">
                    Thank You for your response!
                </div>
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doSurveyResponse: (response) => dispatch(surveyResponse(response)),
});

const mapStateToProps = (state) => ({
    surveyLoading: state.webpraisal.surveyLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
