import React from "react";

import { connect } from "react-redux";

import SingleMultipleOption from "../components/lander/SingleMultipleOption";
import SingleUploadPanel from "../components/lander/SingleUploadPanel";
import MultiUploadPanel from "../components/lander/MultiUploadPanel";
import Header from "../components/layout/Header";

function Lander({
    landerPanel,
}) {
    return (
        <div className="px-4 pt-4 bg-off-white">
            <Header/>

            <SingleMultipleOption />

            { landerPanel === "Single" &&
                <SingleUploadPanel/>
            }
            { landerPanel === "Multiple" &&
                <MultiUploadPanel/>
            }
            <div className="h-10">

            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    landerPanel: state.webpraisal.landerPanel,
});

export default connect(mapStateToProps, mapDispatchToProps)(Lander);
