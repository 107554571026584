import { all, fork } from "redux-saga/effects";
import dataSaga from './sagas/dataSagas';
import UserSaga from './sagas/UserSagas';
import FirebaseSaga from './sagas/FirebaseSaga';
import AnalyticsSaga from "./sagas/AnalyticsSaga";

function* rootSaga() {
  yield all([
    yield fork(dataSaga),
    yield fork(UserSaga),
    yield fork(FirebaseSaga),
    yield fork(AnalyticsSaga),
    // Add other sagas here if needed
  ]);
}

export default rootSaga;