import React, { useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    authLogout,
    firebaseGetCustomToken,
} from '../../store/Actions';


function ClientLayout({
    doAuthLogout,
    doFirebaseGetCustomToken,
    state,
    user,
    children,
}) {
    const memoizedDoFirebaseGetCustomToken = useCallback(() => {
        doFirebaseGetCustomToken(user.user.uid);
    }, [doFirebaseGetCustomToken, user.user.uid])

    useEffect(() => {
        memoizedDoFirebaseGetCustomToken();
    }, [memoizedDoFirebaseGetCustomToken]);

    const handleLogout = () => {
        doAuthLogout();
    }

    return (
        <div className="bg-blue-100/50 min-h-screen">
            <div className="flex flex-col w-60 h-screen float-left text-center fixed">
                <div className="w-full pl-3 text-lg shadow-lg">
                    <div className="mt-3 text-center">Price Chant</div>
                </div>
                <div className="flex flex-col shadow-lg h-full relative">
                    <Link to="/admin" className="bg-orange-200 hover:bg-indigo-500/80 p-2 m-2">Admin</Link>
                    <Link to="/client" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Home</Link>
                    <Link to="/client/collections" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">List Collections</Link>
                    <Link to="/client/collections/edit" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Edit Collections</Link>
                    <Link to="/client/collection" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Collection (Lot)</Link>
                    <Link to="/client" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Create Listing Step One</Link>
                    <Link to="/client" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Upload Images and Add Items</Link>
                    <Link to="/client" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Item Edit</Link>
                    <button className="bg-indigo-200 hover:bg-indigo-500/80 bottom-0 absolute w-full h-10" onClick={handleLogout}>Logout</button>
                </div>
            </div>

            <div className="max-w-7xl ml-60">
                { children }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doAuthLogout: () => dispatch(authLogout()),
    doFirebaseGetCustomToken: (uuid) => dispatch(firebaseGetCustomToken(uuid)),
});

const mapStateToProps = (state) => ({
    state: state.firestore,
    user: state.userSlice.user,
    token: state.userSlice.customToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientLayout);