import {
    SET_WEBPRAISAL_VALUE,
    APPEND_WEBPRAISAL_VALUE,
    SET_WEBPRAISAL_STATUS,
    SET_IS_LOADING_TRUE,
    REMOVE_WORK,
} from '../../store/Actions';

const initialState = {
    isError: false,
    isLoading: false,
    results: [],
    workToBeDone: [],
    landerPanel: "Single",
    loadBar: 0,
    loadingBoxFade: false,
    resultsBoxFade: false,
    eBayPriceCheckIsLoading: true,
    eBayPriceCheckResults: [],
};

const WebpraisalReducer = (state = initialState, action) => {
    switch(action.type) {
        case REMOVE_WORK:
            return {
                ...state,
                workToBeDone: state.workToBeDone.filter(work => work.preview !== action.payload)
            }
        case SET_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_WEBPRAISAL_STATUS:
            let statusReturn = { ...state };
            statusReturn["workToBeDone"][action.payload.index]["status"] = action.payload.status;
            return statusReturn;
        case APPEND_WEBPRAISAL_VALUE:
            const appendItems = [
                ...state[action.payload.index],
            ];
            appendItems.push(action.payload.value);
            let returnAppend = { ...state };
            returnAppend[action.payload.index] = appendItems;
            return returnAppend;
        case SET_WEBPRAISAL_VALUE:
            let returnValue = { ...state };
            returnValue[action.payload.index] = action.payload.value;
            return returnValue;
        default:
            return {
                ...state,
            }
    }
}

export default WebpraisalReducer;