import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";

import AdminLayout from "../../components/admin/Layout";
import {
    firebaseAnalytics,
} from "../../store/Actions";

const Admin = ({
    doFirebaseAnalytics,
    analytics,
}) => {
    const [graphData, setGraphData] = useState([]);
    const memoizedDoFirebaseAnalytics = useCallback(() => {
        doFirebaseAnalytics();
    }, [doFirebaseAnalytics]);

    useEffect(() => {
        memoizedDoFirebaseAnalytics()
    }, [memoizedDoFirebaseAnalytics]);

    useEffect(() => {

        let graphDataToPush = [];
        graphDataToPush.push([
            {
                type: "date",
                label: "Day"
            },
            "requests",
            "failed",
            "successful",
            "noJson",
            "averageBetweenCreatedAndCompleted",
            "averageAssistantResponse",
        ]);
        // setGraphData([]);
        for (const key in analytics) {
            if (analytics.hasOwnProperty(key)) {
                console.log(analytics[key]["date"]);
                graphDataToPush.push([
                    new Date(analytics[key]["date"][0], analytics[key]["date"][1], analytics[key]["date"][2]),
                    analytics[key]["requests"],
                    analytics[key]["failed"],
                    analytics[key]["successful"],
                    analytics[key]["noJson"],
                    analytics[key]["averageBetweenCreatedAndCompleted"],
                    analytics[key]["averageAssistantResponse"],
                ]);
            }
        }
        setGraphData(graphDataToPush);
    }, [analytics]);


    const options = {
        chart: {
        title: "Performance of requests made",
        },
        width: 900,
        height: 500,
        series: {
            0: { lineWidth: 2 },
            1: { lineWidth: 1 },
            2: { lineWidth: 1 },
            4: { lineWidth: 1 },
            5: { lineWidth: 1 },
        },
        pointSize: 10,
    };

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">Home</div>
            <div className="flex flex-col w-fit p-5 m-5 bg-white pb-32">

                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={graphData}
                  options={options}
                />
            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseAnalytics: () => dispatch(firebaseAnalytics()),
});

const mapStateToProps = (state) => ({
    analytics: state.firebase.analytics,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);