import React, { useState } from "react";

import { connect } from "react-redux";

function BouncyButton({
    children,
    handleSubmit,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
    <>
        <div className="text-center bouncy-button-container relative m-auto w-fit hover:cursor-pointer h-12 z-20"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}
        >
            <div className="absolute w-full bg-gray-600 h-12 -z-10 rounded-lg">
            </div>
            <button className={`bg-teal-button rounded-lg text-white
                text-2xl py-2 px-4 bouncy-button z-10 w-fit ${activeClass}`}
                onClick={handleSubmit}
            >
                { children }
            </button>
        </div>
    </>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BouncyButton);
