import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseGetSettings,
    firebaseUpdateSettings,
    firebaseGetAverageTimeUpdate,
} from "../../store/Actions";

const Admin = ({
    doFirebaseGetSettings,
    doFirebaseUpdateSettings,
    doFirebaseGetAverageTimeUpdate,
    openAiTurbo,
    user,
    average,
}) => {
    const [openAiEdit, setOpenAiEdit] = useState(openAiTurbo);

    const memoizedDoFirebaseGetSettings = useCallback(() => {
        doFirebaseGetSettings()
        setOpenAiEdit(openAiTurbo);
    }, [doFirebaseGetSettings, openAiTurbo]);

    useEffect(() => {
        memoizedDoFirebaseGetSettings()
    }, [memoizedDoFirebaseGetSettings]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setOpenAiEdit(value);
    };

    const handleOpenAiUpdate = () => {
        doFirebaseUpdateSettings({
            accessToken: user.customToken,
            "openAiTurbo": openAiEdit,
        });
    };

    const handleAverageTimeUpdate = () => {
        doFirebaseGetAverageTimeUpdate();
    }

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">Home</div>
            <div className="flex flex-col max-w-xl p-5">
                <div className="text-3xl p-2">Chat GPT Turbo System Role</div>
                 <textarea className="text-2xl rounded-lg shadow-lg p-3 w-full"
                    rows="7"
                    value={openAiEdit}
                    onChange={handleInputChange}/>
                <button className="bg-green-100 rounded-xl w-fit text-3xl p-2 mx-auto my-2"
                    onClick={handleOpenAiUpdate}>
                    Update
                </button>
                <span>Generate the following:\n\n1. Title: Based on photos and information exact match online\n\n2. Price: Provide USD Price only\n\n3. Description: Based on photos and information exact match online, provide full item description.  Include specifications model, make, year, and information in Photos uploaded.\n\nReturn in a json string.</span>

                <div className="pt-10">
                    <div className="text-center">
                        Average Time To Complete Requests
                    </div>
                    <div className="text-center">
                        { average }
                    </div>
                    <div className="text-center">
                        <button className="bg-green-100 rounded-xl w-fit text-3xl p-2 mx-auto my-2"
                            onClick={handleAverageTimeUpdate}>
                            Update Average
                        </button>
                    </div>
                </div>

            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetSettings: () => dispatch(firebaseGetSettings()),
    doFirebaseUpdateSettings: (data) => dispatch(firebaseUpdateSettings(data)),
    doFirebaseGetAverageTimeUpdate: () => dispatch(firebaseGetAverageTimeUpdate()),
});

const mapStateToProps = (state) => ({
    openAiTurbo: state.firebase.settings.openAiTurbo,
    average: state.firebase.settings.average,
    user: state.userSlice,
    token: state.userSlice.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);