import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

function LoadingBox({
    isLoading,
    loadBar,
    loadingBoxFade,
}) {
 const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
    const [shadowOpacity, setShadowOpacity] = useState(0);

    useEffect(() => {
        const rotateBox = () => {
            const newRotation = getRandomRotation();
            setRotation(newRotation);

            // Update shadow based on the Y-axis rotation
            const newShadowOpacity = (Math.abs(newRotation.y) % 360) / 360;
            setShadowOpacity(newShadowOpacity);
        };

        rotateBox(); // Initial rotation
        const interval = setInterval(rotateBox, 2000); // Change direction every 2 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    function getRandomRotation() {
        const xDegrees = Math.random() * 360;
        const yDegrees = Math.random() * 360;
        const zDegrees = Math.random() * 360;
        return { x: xDegrees, y: yDegrees, z: zDegrees };
    }

    return (
        <div className={`m-auto loading-panel ${loadingBoxFade&& "fade-out"}`}>
            <div className="box" style={{
                    transition: 'transform 5s',
                    transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) rotateZ(${rotation.z}deg)`,
                }}>
                <div className="face front"></div>
                <div className="face back"></div>
                <div className="face right"></div>
                <div className="face left"></div>
                <div className="face top"></div>
                <div className="face bottom"></div>
            </div>
            <div className="shadow" style={{
                    boxShadow: `0px 10px 20px rgba(0, 0, 0, ${shadowOpacity})`,
                }}>
            </div>
            <div className="bg-gray-300 mt-16 h-5">
                <div style={{ width: `${loadBar * 25}%` }} className={`bg-blue-300 h-5`}></div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
    loadBar: state.webpraisal.loadBar,
    loadingBoxFade: state.webpraisal.loadingBoxFade,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingBox);
