import React, { useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";

import LoadingBox from "../lander/LoadingBox";
import Survey from "../lander/Survey";
import PriceChantButton from "../lander/PriceChantButton";
import DisplayResults from "../lander/DisplayResults";
import SingleUploadDescription from "../lander/SingleUploadDescription";

import {
    processWorkAsAssistant,
    appendWebpraisalValue,
    setWebpraisalValue,
    removeWork,
} from "../../store/Actions";

function SingleUploadPanel({
    doProcessWorkAsAssistant,
    doSetWebpraisalValue,
    doAppendWebpraisalValue,
    doRemoveWork,
    workToBeDone,
    results,
    isLoading,
    isError,
    status,
    showSurvey,
    isAdmin = true,
}) {
    const targetRef = useRef(null);
    const recaptchaRef = React.createRef();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };
    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const defaultForm = useMemo(() => {
        return {
            item: "",
        }
    }, []);
    const [formData, setFormData] = useState(defaultForm);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const toDataUrl = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const scaledataURL = (datas, scale) => {
        return new Promise(async function(resolve,reject){
            const _scale = scale;
            var img = document.createElement('img');
            img.onload = function()
            {
                var canvas = document.createElement('canvas');
                canvas.width = img.width * _scale;
                canvas.height = img.height * _scale;

                var ctx = canvas.getContext("2d");
                // var cw = canvas.width;
                // var ch = canvas.height;
                var maxW = img.width * _scale;
                var maxH = img.height * _scale;

                var iw = img.width;
                var ih = img.height;
                var scl = Math.min((maxW / iw), (maxH / ih));
                var iwScaled = iw * scl;
                var ihScaled = ih * scl;
                canvas.width = iwScaled;
                canvas.height = ihScaled;
                ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
                const newBase64 = canvas.toDataURL("image/jpeg", scl);

                resolve(newBase64);
            };

            img.src = datas;

        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (recaptchaValue || isAdmin) {
            if (Object.keys(workToBeDone).length === 0 && formData["item"] === "") return;
            if (formData["item"] !== "") {
                await doAppendWebpraisalValue({
                    index:"workToBeDone",
                    value: {
                        type: "text",
                        data: formData["item"],
                        path: formData["item"],
                        index: workToBeDone.length,
                        status: "waiting",
                        preview: "/images/csv.jpg",
                    }
                });
            }
            await doProcessWorkAsAssistant(workToBeDone);
            await setFormData(defaultForm);

            if (window.innerWidth < 730) {
                await scrollToTarget();
            }
        }
    }

    const handleImageDelete = imageIndex => () => {
        doRemoveWork(imageIndex);
    }

    const addFilesToDropzone = (files) => {
        files.length = 3;
        if (workToBeDone.length >= 3) return;
        files.map((file, index) => {
            if (file.type.indexOf("image/jpeg") === -1) return false;
            toDataUrl(URL.createObjectURL(file), async function(myBase64) {
                const previewUrl = await URL.createObjectURL(file);
                let newDataUri;
                if (file.size > 100000) {
                    newDataUri = await scaledataURL(myBase64,.30);
                } else {
                    newDataUri = await scaledataURL(myBase64,.70);
                }
                await doAppendWebpraisalValue({
                    index:"workToBeDone",
                    value: {
                        ...file,
                        type: "image",
                        data: newDataUri,
                        index: index,
                        status: "waiting",
                        preview: previewUrl,
                    }
                });
            });
            return true;
        });
    }

    return (
        <>
        <div className="max-w-6xl m-auto grid grid-cols-1 md:grid-cols-2 gap-4 pt-4 md:pt-12 px-4">
            <div>
                <div className="text-center md:pt-10 text-4xl md:text-5xl font-extrabold text-teal-text leading-tight">
                    Snap, Upload, Discover with <i>PriceChant</i>!
                </div>
                <SingleUploadDescription />
                <div className="pt-3 md:mx-10 my-2">
                    <div className="w-full border-2 border-dotted border-dark-blue rounded-xl py-10 px-5 bg-smoke-white">
                        <Dropzone
                        onDrop={files => {
                        addFilesToDropzone(files);
                        }}>
    {({ getRootProps, getInputProps }) => (
        <div className="grid grid-cols-3 gap-2">
            {workToBeDone.map((file, index) => {
                if (file.type !== "image") return false;
                return(
                <div key={`${file.path}_${index}`}
                    className={`border-2 w-full h-full rounded-lg overflow-hidden relative justify-between flex flex-col
                        ${ file.status === "waiting" && "bg-stone-200"}
                        ${ file.status === "complete" && "bg-green-500"}
                        ${ file.status === "loading" && "bg-blue-200"}
                         ${ file.status === "error" && "bg-red-500"}`}>
                    <button className="absolute right-1">
                        { !isLoading &&
                            <img src="/images/blue_x.png" alt="remove" onClick={handleImageDelete(file.preview)}/>
                        }
                    </button>
                    <img src={file.preview} alt={file.path} className="max-h-24 m-auto"/>
                </div>
                )
            })}
            { (workToBeDone.length === 0 || workToBeDone[0]?.type === "text") &&
                <div {...getRootProps()} className="col-span-1 col-start-2 max-h-24">
                    <input {...getInputProps()} className=""/>
                    <img src="/images/uploadImage.png" alt="upload icon" className="max-h-24 m-auto hover:cursor-pointer"/>
                </div>
            }
            { (workToBeDone.length < 3 && workToBeDone.length !== 0 && workToBeDone[0]?.type !== "text") &&
                <div {...getRootProps()}>
                    <input {...getInputProps()} className="max-h-24"/>
                    <img src="/images/uploadImage.png" alt="upload icon" className="max-h-24 m-auto hover:cursor-pointer"/>
                </div>
            }
        </div>
    )}
                        </Dropzone>
                    </div>
                    <div className="text-center md:mx-10">
                        <input className="p-2 my-6 text-xl text-left bg-smoke-white border-1 border-gray-300 rounded-xl hover:border-blue-300 focus:outline-none focus:border-blue-400 text-center w-full text-teal-text"
                            type="text"
                            placeholder="Title (optional)..."
                            onChange={ handleInputChange }
                            value={ formData.item }
                            name={"item"}
                            />
                    </div>
                    <form onSubmit={handleSubmit}>
                        {   !isAdmin &&
                            <div className="pt-1 pb-5 m-auto w-fit">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LdYqjQpAAAAAFNpjK2xVMybi7Z7PaWe5K8379J4"
                                    onChange={handleRecaptchaChange}
                                />
                            </div>
                        }
                        <PriceChantButton>
                            PriceChant
                        </PriceChantButton>
                    </form>
                    { isError === true &&
                        <div className="text-center bg-red-200">Error Has Occurred, try again please</div>
                    }
                    { showSurvey &&
                       <Survey/>
                    }
                </div>
            </div>
            <div>
                <div className="rounded-xl border-1 border-dark-blue overflow-hidden relative h-full justify-center flex flex-col bg-smoke-white">
                    { (Object.keys(results).length !== 0 && isLoading === false)  &&
                        <DisplayResults results={results}/>
                    }
                    { (Object.keys(results).length === 0 && isLoading === false)  &&
                        <div className="h-full bg-peach justify-center flex flex-col">
                            <img className="flex" src="/images/priceChantHero.jpg" alt="hand holding a cell phone"/>
                        </div>
                    }
                    { (Object.keys(results).length === 0 && isLoading === true ) &&
                        <div className="m-auto flex flex-col pt-10 md:pt-48">
                            <LoadingBox/>
                            <div className="text-center pt-10 text-lg">
                                { status }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="max-w-6xl m-auto grid grid-cols-1 md:grid-cols-2 gap-24 pt-10 px-4" ref={targetRef}>
            <div className="md:col-start-2 grid grid-cols-2 gap-4">
                <div className="m-auto rounded-lg overflow-hidden">
                    <img src="/images/appleIcon.jpg" alt="Apple Icon"/>
                </div>
                <div className="m-auto rounded-lg overflow-hidden">
                    <img src="/images/googleIcon.jpg" alt="Google Icon"/>
                </div>
            </div>
        </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doProcessWorkAsAssistant: (data) => dispatch(processWorkAsAssistant(data)),
    doAppendWebpraisalValue: (data) => dispatch(appendWebpraisalValue(data)),
    doSetWebpraisalValue: (data) => dispatch(setWebpraisalValue(data)),
    doRemoveWork: (data) => dispatch(removeWork(data)),
});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
    isError: state.webpraisal.isError,
    results: state.webpraisal.results,
    workToBeDone: state.webpraisal.workToBeDone,
    status: state.webpraisal.status,
    showSurvey: state.webpraisal.showSurvey
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleUploadPanel);
