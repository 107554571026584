import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
    landerChangePanel,
} from "../../store/Actions";

function SingleMulitpleOption({
    doLanderChangePanel,
}) {
    const [activeButton, setActiveButton] = useState("Single");
    const [activeClass, setActiveClass] = useState("");

    useEffect(() => {
        doLanderChangePanel("Single");
    }, [doLanderChangePanel]);

    const handleMouseEnter = () => {
        if (activeButton === "Single") return;
        if (activeButton === "Multiple") {
            setActiveClass("bouncy-button-in");
        }
    };

    const handleMouseLeave = () => {
        if (activeButton === "Single") return;
        setActiveClass("bouncy-button-active");
    };

    const handleClick = () => {
        doLanderChangePanel("Single");
        if (activeButton === "Multiple") {
            setActiveClass("bouncy-button-out");
            setActiveButton("Single");
            setActiveClassTwo("bouncy-button-active");
        }
    };

    const handleUnclick = () => {
        if (activeButton === "Multiple") {
            setActiveClass("bouncy-button-in");
        }
    };

    const [activeClassTwo, setActiveClassTwo] = useState("bouncy-button-active");

    const handleMouseEnterTwo = () => {
        if (activeButton === "Single") {
            setActiveClassTwo("bouncy-button-in");
        }
    };

    const handleMouseLeaveTwo = () => {
        if (activeButton === "Multiple") return;
        setActiveClassTwo("bouncy-button-active");
    };

    const handleClickTwo = () => {
        doLanderChangePanel("Multiple");
        if (activeButton === "Single") {
            setActiveClassTwo("bouncy-button-out");
            setActiveButton("Multiple");
            setActiveClass("bouncy-button-active");
        }
    };

    const handleUnclickTwo = () => {
        if (activeButton === "Single") {
            setActiveClassTwo("bouncy-button-in");
        }
    };

    return (
        <>
            <div className="w-48 m-auto flex flex-row rounded-lg text-lg grid grid-cols-2 text-center mt-8 relative">
                <div className="bg-gray-600 rounded-lg absolute z-0 w-full h-full"></div>
                <div className="w-full z-10"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleClick}
                    onMouseUp={handleUnclick}
                >
                    <button className={`bg-teal-button rounded-l-md text-white p-1 w-full bouncy-button ${activeClass}`}>Demo</button>
                </div>
                <div className="w-full z-10"
                    onMouseEnter={handleMouseEnterTwo}
                    onMouseLeave={handleMouseLeaveTwo}
                    onMouseDown={handleClickTwo}
                    onMouseUp={handleUnclickTwo}
                >
                    <button className={`bg-peach rounded-r-md p-1 text-teal-text w-full bouncy-button ${activeClassTwo}`}>Pro</button>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doLanderChangePanel: (data) => dispatch(landerChangePanel(data)),
});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleMulitpleOption);
