import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseAdminRequests,
} from "../../store/Actions";

const AdminRequests = ({
    doFirebaseAdminRequests,
    userToken,
    requests,
    hasPreviousPage,
    hasMore,
}) => {
    const { id, cursorType } = useParams();

    const memoizedDoFirebaseAdminRequests = useCallback(() => {
        let slugToUse = "none";
        if (typeof id !== "undefined") {
            slugToUse = id;
        }

        let cursorTypeToUse = "startAfter";
        if (typeof cursorType !== "undefined") {
            cursorTypeToUse = cursorType;
        }
        doFirebaseAdminRequests({
            limit: 10,
            cursorEntity: slugToUse,
            cursorType: cursorTypeToUse,
            userToken: userToken,
        });
    }, [doFirebaseAdminRequests, id, userToken, cursorType]);

    useEffect(() => {
        memoizedDoFirebaseAdminRequests()
    }, [memoizedDoFirebaseAdminRequests]);

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">
                Requests
            </div>
            <div className="flex flex-col max-w-4xl p-5">
                <div className="p-10">
                    <table class="table-auto bg-slate-400 text-center">
                        <thead className="bg-slate-500 text-lg">
                            <tr>
                                <th>id</th>
                                <th className="w-96">created</th>
                                <th className="w-96">search term</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-xl">
                            {
                                requests.map((request) => {
                                    const date = new Date(request.created.seconds * 1000);
                                    const displayDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                                    return (
                                        <tr className="hover:bg-slate-300" key={request.id}>
                                            <td>{ request.id.substring(0, 5) }...</td>
                                            <td className="">{ displayDate }</td>
                                            <td className="">{ request.searchTerm.substring(0, 30) }...</td>
                                            <td className="float-right">
                                                <div className="flex flex-row">
                                                <button className="m-2 p-2 bg-green-200 hover:bg-green-300 rounded-md">edit</button>
                                                <Link to={`/admin/request/${request.id}`}className="m-2 p-2 bg-blue-200 hover:bg-blue-300 rounded-md">view</Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="grid grid-cols-2 gap-10 text-center pt-5">
                        { hasPreviousPage &&
                            <Link to={`/admin/requests/${hasPreviousPage}/previous`} className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2">Previous</Link>
                        }
                        { hasMore &&
                            <Link to={`/admin/requests/${hasMore}/next`} className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2 col-start-2">Next</Link>
                        }
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseAdminRequests: (data) => dispatch(firebaseAdminRequests(data)),
});

const mapStateToProps = (state) => ({
    requests: state.firebase.requests.edges,
    hasMore: state.firebase.requests.hasMore,
    hasPreviousPage: state.firebase.requests.hasPreviousPage,
    userToken: state.userSlice.customToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRequests);