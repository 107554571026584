import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const empowered = [
    "Appraisals",
    "Cataloging",
    "Asset Sales",
    "Research",
];

const driven = [
    "Item Recognition",
    "Industry Analysis",
    "Asset Descriptions",
    "Consumer Insights",
    "Pricing Trends",
];

const TextTypingEffect = ({ textArray, typingSpeed = 150, fontColor }) => {
    const [currentText, setCurrentText] = useState('');
    const [arrayIndex, setArrayIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        if (arrayIndex >= textArray.length) {
            setArrayIndex(0);
            return;
        }

        if (charIndex < textArray[arrayIndex].length) {
            const timer = setTimeout(() => {
                setCurrentText((prev) => prev + textArray[arrayIndex][charIndex]);
                setCharIndex(charIndex + 1);
            }, typingSpeed);

            return () => clearTimeout(timer);
        } else {
            // Reset for next text after a pause
            const nextTextTimer = setTimeout(() => {
                setCurrentText('');
                setCharIndex(0);
                setArrayIndex(arrayIndex + 1);
            }, 3500);

            return () => clearTimeout(nextTextTimer);
        }
    }, [currentText, arrayIndex, charIndex, textArray, typingSpeed]);

    return <div className={`w-fit border-b-2 h-8 mx-1 font-bold ${fontColor}`}>{currentText}</div>;
};

function SingleUploadDescription() {

    useEffect(() => {

    }, []);

    return (
        <div className="text-xl text-center pt-3 md:pt-10 px-2 text-teal-text flex flex-col">
            <div className="flex flex-row justify-center h-8">
                Empower your <TextTypingEffect textArray={empowered} typingSpeed={100} fontColor="text-teal-button"/>
            </div>
            <div className="flex flex-row justify-center h-8">
                with AI-driven <TextTypingEffect textArray={driven} typingSpeed={100} fontColor="text-dark-peach"/>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SingleUploadDescription);
