import React, { useState } from "react";
import { connect } from 'react-redux';

import {
    authLogin,
} from '../../store/Actions';

import Header from "../../components/layout/Header";
import BouncyButton from "../../components/lander/BouncyButton";
const Login = ({
    doAuthLogin,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleLogin = () => {
        console.log("login")
        doAuthLogin({email, password});
    };
    return (
        <div className="px-4 pt-4 bg-off-white">
            <Header/>

            <div className="flex flex-col max-w-md m-auto mt-20 border border-md shadow-lg">
                <div className="bg-gray-300/70 p-5">
                    <div className="text-xl">Login</div>
                        Email:
                        <br />
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3"
                        />
                    <br />
                        Password:
                    <br />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3"
                        />
                    <br />
                    <br />

                    <BouncyButton handleSubmit={handleLogin}>
                        Log In
                    </BouncyButton>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    doAuthLogin: (user) => dispatch(authLogin(user)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);