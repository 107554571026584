import { all, fork, takeLeading, call } from "redux-saga/effects";

import {
    TRACK_EVENT,
    FIREBASE_COLLECT_EMAIL,
    PROCESS_WORK_AS_ASSISTANT,
    SURVEY_RESPONSE,
    FIREBASE_CONTACT_US,
} from "../../store/Actions";
import ReactGA from "react-ga4";

function* handleTrackEvent(action) {
    // const analytics = getAnalytics();
    const { eventName, category } = action.payload;
    // yield call(logEvent, analytics, eventName, params);
    yield call(ReactGA.event({
        category: category,
        action: eventName,
        // label: "your label", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
    }));
}

function* handleContactUsEvent(action) {
    yield call(ReactGA.event,{
        category: "generate_lead",
        action: "generate_lead",
    });
}

function* handleEmailCollectEvent(action) {
    yield call(ReactGA.event,{
        category: "generate_lead",
        action: "generate_lead",
    });
}

function* handlePriceCheck(action) {
    yield call(ReactGA.event,{
        category: "price_check_event",
        action: "price_check_event",
    });
}

function* handleSurveyResponse(action) {
    yield call(ReactGA.event,{
        category: "survey_response_event",
        action: "survey_response_event",
    });
}

function* watchDataSaga() {
    yield takeLeading(TRACK_EVENT, handleTrackEvent);
    yield takeLeading(FIREBASE_COLLECT_EMAIL, handleEmailCollectEvent);
    yield takeLeading(PROCESS_WORK_AS_ASSISTANT, handlePriceCheck);
    yield takeLeading(SURVEY_RESPONSE, handleSurveyResponse);
    yield takeLeading(FIREBASE_CONTACT_US, handleContactUsEvent);
}

export default function* analyticsSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
