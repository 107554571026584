import React, { useState } from "react";

import { connect } from "react-redux";
import CollectionsItemEditButton from "../client/CollectionsItemEditButton";
function CollectionItem({
    index,
    children,
    handleSubmit,
}) {
    const [activeClass, setActiveClass] = useState("");

    const bgColors = [
        "bg-red",
        "bg-orange",
        "bg-green",
        "bg-blue",
        "bg-yellow",
        "bg-amber",
        "bg-lime",
        "bg-stone",
        "bg-emerald",
        "bg-teal",
        "bg-cyan",
        "bg-sky",
        "bg-violet",
        "bg-purple",
        "bg-fuchsia",
        "bg-pink",
        "bg-rose",
    ];
    const randomBgColorArray = bgColors[Math.floor(Math.random() * bgColors.length)];
    const [randomBgColor] = useState(`${randomBgColorArray}-300`);

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }

    return (
        <div
            index={index}
            className={`relative`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}>
            <div className="absolute rounded-2xl bg-gray-200 h-full w-full z-10"></div>
            <div className={`flex flex-col ${randomBgColor} rounded-2xl shadow-md overflow-hidden max-w-20 bouncy-button ${activeClass} relative z-20`}>

                <div className="z-10 hover:cursor-pointer relative">
                    <div className="absolute bg-smoke-white w-10 h-20 right-0 -z-10"></div>
                    <div className={`flex flex-row justify-between p-2 text-xl z-20 rounded-br-xl ${randomBgColor}`}>
                        <div className="flex flex-col justify-center">
                            Nes Video Games
                        </div>
                        <CollectionsItemEditButton index={`${index}-button`} bgColor={randomBgColorArray}>
                            Edit Lot
                        </CollectionsItemEditButton>
                    </div>
                </div>
                <div className="rounded-t-2xl overflow-hidden flex flex-row bg-smoke-white">

                    <div className="w-1/4 flex flex-col relative overflow-hidden relative m-1 rounded-2xl hover:cursor-pointer relative">
                        <img className="rounded-br-2xl z-10 mb-5" src="/test-images/marioCatridge.jpg" alt="mario"/>
                        <div className={`${randomBgColor} bottom-o justify-self-end text-center w-full absolute bottom-0 rounded-tl-2xl z-20 h-1/3`}>
                            Super Mario...<br/>
                            $19.99
                        </div>
                    </div>
                    <div className="w-1/4 flex flex-col relative overflow-hidden relative m-1 rounded-2xl hover:cursor-pointer relative">
                        <img className="rounded-br-2xl z-10 mb-5" src="/test-images/marioCatridge.jpg" alt="mario"/>
                        <div className={`${randomBgColor} bottom-o justify-self-end text-center w-full absolute bottom-0 rounded-tl-2xl z-20 h-1/3`}>
                            Super Mario...<br/>
                            $19.99
                        </div>
                    </div>
                    <div className="w-1/4 flex flex-col relative overflow-hidden relative m-1 rounded-2xl hover:cursor-pointer relative">
                        <img className="rounded-br-2xl z-10 mb-5" src="/test-images/marioCatridge.jpg" alt="mario"/>
                        <div className={`${randomBgColor} bottom-o justify-self-end text-center w-full absolute bottom-0 rounded-tl-2xl z-20 h-1/3`}>
                            Super Mario...<br/>
                            $19.99
                        </div>
                    </div>
                    <div className="rounded-2xl flex flex-col text-center hover:cursor-pointer overflow-hidden relative m-1">
                        <div className={`h-1/2 ${randomBgColor} rounded-br-2xl z-10 flex flex-col justify-center`}>
                            <div className="text-3xl p-1">Add Item</div>
                        </div>
                        <div className="bg-white h-1/2 w-full absolute top-0"></div>
                        <div className={`${randomBgColor} h-1/2 w-full absolute bottom-0`}></div>
                        <div className="h-1/2 bg-white rounded-tl-2xl z-10">
                            <div className="text-3xl">+</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionItem);
