import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MenuButton from "../../components/lander/MenuButton";

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    return (
        <header className="max-w-6xl m-auto text-left text-xl flex flex-row justify-between px-4">
            <div className="justify-between text-teal-text italic text-3xl font-extrabold">
                <Link to="/">PriceChant</Link>
            </div>
            <nav className="hidden md:flex flex-row grid grid-cols-2 gap-2 text-lg">
                <MenuButton to="/login">
                    Login
                </MenuButton>
                <MenuButton to="/contact">
                    Contact
                </MenuButton>
            </nav>
             <div className="float-right text-right md:hidden w-1/4">
                <button type="button"
                    className=" inline-flex items-center justify-center rounded-md p-2.5 text-gray-700  bg-teal-button"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                    <span className="sr-only">Open main menu</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            <div className={`h-32 max-w-6xl md:hidden ${isNavOpen ? "" : "hidden"}`} role="dialog" aria-modal="true">
                <div className="fixed inset-0 z-50"></div>
                <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-8 py-3 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <div className="justify-between text-teal-text italic text-3xl font-extrabold">
                            <Link to="/">PriceChant</Link>
                        </div>
                        <button type="button"
                            className=" rounded-md p-2.5 text-gray-700"
                            onClick={() => setIsNavOpen(false)}>
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6 flex flex-col">
                                <Link to="/login" className="bg-blue-button hover:bg-blue-500
                                    rounded-lg p-1 m-1 font-bold text-black text-center">Login</Link>
                                <Link to="/contact" className="bg-green-button hover:bg-green-500
                                    rounded-lg p-1 m-1 font-bold text-black text-center">Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
