import React, { useState } from "react";
import { connect } from "react-redux";
import BouncyButton from "../lander/BouncyButton";
import {
    firebaseCollectEmail,
} from "../../store/Actions";
import ReCAPTCHA from "react-google-recaptcha";

function MultiUploadPanel({
    doFirebaseCollectEmail,
    firebaseIsLoading,
    emailSubmitted,
}) {
    const recaptchaRef = React.createRef();
    const [email, setEmail] = useState("");
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleInputChange = (event) => {
        console.log(event.target.value);
        setEmail(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (recaptchaValue) {
            if (email === "") return;
            doFirebaseCollectEmail(email);
        }
    }
    return (
        <div className="max-w-6xl m-auto grid grid-cols-1 md:grid-cols-2 gap-4 pt-12 px-4">
            <div>
                <div className="rounded-xl border-1 border-dark-blue overflow-hidden relative h-full flex flex-col justify-center bg-teal-button">
                    <img src="/images/priceChantSuite.jpg" alt="man working on computer"/>
                </div>
            </div>
            <div>
                <div className="text-center md:pt-10 mx-2 text-4xl md:text-5xl font-extrabold text-teal-text leading-tight">
                    PriceChant's Business Suite Beta!
                </div>
                <div className="text-left pt-10 text-teal-text px-5">
                   Discover PriceChant's Business Suite, a groundbreaking tool for appraisers and sellers.<br/><br/>

Why You Should Join:<br/>
<ul className="pt-1">
<li className=""><b>1. AI-Powered Cataloging:</b> Through AI-driven object recognition, generate accurate titles, descriptions, and market valuations effortlessly.</li>
<li className="pt-1">
<b>2. Efficient Asset Management:</b> Cataloging platform and customizable reporting system.</li>
<li className="pt-1">
<b>3. AI-Driven Market Analysis:</b> Stay ahead with AI-generated insights into market trends and asset valuations.</li>
                </ul>
                </div>
                { (firebaseIsLoading && emailSubmitted) &&
                    <div className="text-center pt-10 text-teal-text text-2xl">
                        Thanks for registering for PriceChant's beta access! You will receive an email notification once you have been accepted to join.
                    </div>
                }
                { (!emailSubmitted) &&
                    <form onSubmit={handleSubmit}>
                        <div className="text-center md:mx-20">
                            <input className="p-2 my-6 text-xl text-left bg-smoke-white border-1 border-gray-300 rounded-xl hover:border-blue-300 focus:outline-none focus:border-blue-400 text-center w-full text-teal-text"
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={handleInputChange}
                                disabled={emailSubmitted}
                                />
                        </div>
                        <div className="pt-1 pb-5 m-auto w-fit">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdYqjQpAAAAAFNpjK2xVMybi7Z7PaWe5K8379J4"
                                onChange={handleRecaptchaChange}
                            />
                        </div>
                        <BouncyButton>
                            Submit
                        </BouncyButton>
                    </form>
                }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseCollectEmail: data => dispatch(firebaseCollectEmail(data)),
});

const mapStateToProps = (state) => ({
    firebaseIsLoading: state.firebase.isLoading,
    emailSubmitted: state.firebase.emailSubmitted,
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiUploadPanel);
