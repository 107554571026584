import { combineReducers } from "redux";

import WebpraisalReducer from "./reducers/WebpraisalReducer";
import UserSlice from "./reducers/UserSlice";
import FirebaseReducer from "./reducers/FirebaseReducer";

const rootReducer = combineReducers({
    webpraisal: WebpraisalReducer,
    firebase: FirebaseReducer,
    userSlice: UserSlice,
});

export default rootReducer;