import axios from 'axios';

// Extract common function to make code more reusable and maintainable.
const fetchNetlifyFunction = (functionName, body = {}) => {
    return axios.post(`/.netlify/functions/${functionName}`, body);
};

export const fetchOpenAiText = (data) => {
    return fetchNetlifyFunction("openAi", {data});
}

export const fetchOpenAiVision = (data) => {
    return fetchNetlifyFunction("openAiVision", {data});
}

export const fetchFirebaseLogin = (user) => {
    return fetchNetlifyFunction("getFirebaseLogin", { user });
};

export const fetchFirebaseLogout = () => {
  return fetchNetlifyFunction("getFirebaseLogout");
};

export const fetchFirebaseGetCustomToken = (data) => {
    return fetchNetlifyFunction("getFirebaseGetCustomToken", { data });
};

export const fetchGetFirebaseSettings = (data) => {
    return fetchNetlifyFunction("getFirebaseSettings");
}

export const fetchFirebaseSetSetting = (data) => {
    return fetchNetlifyFunction("getFirebaseSetSetting", data);
}

export const fetchOpenAiVisionOneIdentify = (data) => {
    return fetchNetlifyFunction("openAiVisionOneIdentify", data);
}

export const fetchOpenAiPriceCheck = (data) => {
    return fetchNetlifyFunction("openAiPriceCheck", data);
}

export const fetchPerplexityPriceCheck = (data) => {
    return fetchNetlifyFunction("perplexityPriceCheck", data);
}

export const fetchAssistantPriceCheckThread = (data) => {
    return fetchNetlifyFunction("openAiAssistantPriceCheckThread", data);
}

export const fetchAssistantPriceCheckQueue = (data) => {
    return fetchNetlifyFunction("openAiAssistantPriceCheckQueue", data);
}

export const fetchSetSurveyResponse = (data) => {
    return fetchNetlifyFunction("setSurveyResponse", data);
}

export const fetchFirebaseCollectEmail = (data) => {
    return fetchNetlifyFunction("getFirebaseCollectEmail", data);
}

export const fetchGetFirebaseAvaregeTimeUpdate = (data) => {
    return fetchNetlifyFunction("getFirebaseAvaregeTimeUpdate", data);
}

export const fetchGetFirebaseAnalytics = (data) => {
    return fetchNetlifyFunction("getFirebaseAnalytics", data);
}

export const fetchGetFirebaseGetRequests = (data) => {
    return fetchNetlifyFunction("getFirebaseGetRequests", data);
}

export const fetchGetFirebaseGetRequest = (data) => {
    return fetchNetlifyFunction("getFirebaseGetRequest", data);
}

export const fetchGetEbayPriceCheck = (data) => {
    return fetchNetlifyFunction("getEbayPriceCheck", data);
}

export const fetchGetFirebaseGetDocuments = (data) => {
    return fetchNetlifyFunction("getFirebaseDocuments", data);
}