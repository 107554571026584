import React, { useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

function MenuButton({
    children,
    to,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
    <Link to={to}>
        <div className="text-center bouncy-button-container relative m-auto w-fit hover:cursor-pointer z-20"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}
        >
            <div className="absolute w-full bg-gray-600 h-7 -z-10 rounded-full">
            </div>
            <button className={`bg-off-white rounded-full text-black
                text-lg px-4 bouncy-button z-10 w-fit border-1 border-gray-600 ${activeClass}`}
            >
                { children }
            </button>
        </div>
    </Link>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
