import React from "react";
import { connect } from "react-redux";
import ClientLayout from "../../components/client/Layout";
import {

} from "../../store/Actions";

const ClientHome = () => {

    return (
        <ClientLayout>
            <div className="text-3xl h-10 w-full m-2 ml-4 pr-5 flex flex-row justify-between">
                Collections (Lots)
                <div className="bg-gray-100/50 border-2 hover:bg-green-200/50">+ Add Collection</div>
            </div>

            <div className="ml-4">
                <div className="grid grid-cols-3 gap-4">




                </div>

            </div>
        </ClientLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    user: state.userSlice,
    token: state.userSlice.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientHome);