import React, { useEffect } from "react";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import AuthGuard from "./guards/AuthGuard";
import GuestGuard from "./guards/GuestGuard";

// import Home from "./pages/Home";
import Lander2 from "./pages/Lander2";
import Contact from "./pages/Contact";

import Login from "./pages/auth/Login";

import ClientHome from "./pages/client/Home";
import ClientCollections from "./pages/client/Collections";
import ClientCollection from "./pages/client/Collection";

import Admin from "./pages/admin/Admin";
import AdminRequests from "./pages/admin/Requests";
import AdminRequest from "./pages/admin/Request";
import AdminMessages from "./pages/admin/Messages";
import AdminPriceChant from "./pages/admin/PriceChant";
import AdminGraphs from "./pages/admin/Graphs";
import Settings from "./pages/admin/Settings";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-56WVLSHQR9";

function App() {
    const location = useLocation();
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
    }, [location]);
    return (
        <Routes location={location}>
            <Route exact path="/" element={<Lander2 />} />
            <Route exact path="/contact" element={<Contact />} />

            <Route exact path="/login" element={<GuestGuard><Login/></GuestGuard>} />

            <Route path="/client">
                <Route exact path="" element={<AuthGuard><ClientHome/></AuthGuard>}/>
                <Route exact path="collections" element={<AuthGuard><ClientCollections/></AuthGuard>}/>
                <Route exact path="collections/edit" element={<AuthGuard><ClientCollection/></AuthGuard>}/>
                <Route exact path="collection" element={<AuthGuard><ClientCollection/></AuthGuard>}/>
                <Route exact path="create/1" element={<AuthGuard><ClientCollections/></AuthGuard>}/>

            </Route>

            <Route path="/admin">
                <Route path="requests">
                    <Route exact path=":id/:cursorType" element={<AuthGuard><AdminRequests/></AuthGuard>} />
                    <Route exact path="" element={<AuthGuard><AdminRequests/></AuthGuard>} />
                </Route>
                <Route path="messages">
                    <Route exact path="" element={<AuthGuard><AdminMessages/></AuthGuard>} />
                </Route>
                <Route exact path="request/:id" element={<AuthGuard><AdminRequest/></AuthGuard>} />


                <Route exact path="graphs" element={<AuthGuard><AdminGraphs/></AuthGuard>} />
                <Route exact path="settings" element={<AuthGuard><Settings/></AuthGuard>} />
                <Route exact path="price-chant" element={<AuthGuard><AdminPriceChant/></AuthGuard>} />
                <Route exact path="" element={<AuthGuard><Admin/></AuthGuard>} />
            </Route>
        </Routes>
    );
}

export default App;
